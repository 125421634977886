import { render, staticRenderFns } from "./Feedback.vue?vue&type=template&id=26a1ca66&scoped=true&"
import script from "./Feedback.vue?vue&type=script&lang=js&"
export * from "./Feedback.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26a1ca66",
  null
  
)

export default component.exports